import React from "react";
import discordIcon from "../../resources/img/icon/discord.svg";
import twitterIcon from "../../resources/img/icon/twitter.svg";
import openseaIcon from "../../resources/img/icon/opensea.svg";
import instagramIcon from "../../resources/img/icon/instagram.svg";
import tikTokIcon from "../../resources/img/icon/tiktok.svg";
import styled from "styled-components";
import ExternalLink from "./ExternalLink";
import breakpoints from "../../styles/breakpoints";

const SocialLinksContainer = styled.div`
  width: 205px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  a {
    img {
      &:hover {
        opacity: 0.6;
        transition: opacity 0.4s;
      }
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    width: 400px;
  }
`;

const SocialLinks = () => {
  return (
    <SocialLinksContainer>
      <ExternalLink href={"https://opensea.io/collection/pandadynasty"}>
        <img src={openseaIcon} alt={"OpenSea"} />
      </ExternalLink>
      <ExternalLink href={"https://discord.gg/7U5mJXgNPW"}>
        <img src={discordIcon} alt={"Discord"} />
      </ExternalLink>
      <ExternalLink href={"https://twitter.com/PandaD_NFT"}>
        <img src={twitterIcon} alt={"Twitter"} />
      </ExternalLink>
      <ExternalLink href={"https://www.instagram.com/pandadynastynft/"}>
        <img src={instagramIcon} alt={"Instagram"} />
      </ExternalLink>
      <ExternalLink href={"https://www.tiktok.com/@pandadynastynft"}>
        <img src={tikTokIcon} alt={"TikTok"} />
      </ExternalLink>
    </SocialLinksContainer>
  );
};

export default SocialLinks;
