const colors = {
  white: '#FFFFFF',
  black: '#0F0F0F',
  grey: '#272727',
  blue: '#14D1DB',
  green: '#17DAA0',
  greenElectric: '#18E272',
  purple: '#7B70D1',
  darkGreen: '#233217'
};

export default colors;
