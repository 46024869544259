const breakpoints = {
  max: "1600px",
  xxl: "1440px",
  xl: "1280px",
  lg: "992px",
  md: "768px",
  sm: "576px",
  xs: "575px",
  nav: "435px"
};

export default breakpoints;
